<template>
  <div class="page flex-row justify-between">
    <menuTabs />
    <div class="page-right flex-col align-center">
      <el-tabs v-model="pageParams.payStyleList[0]" @tab-click="handleClick">
        <el-tab-pane label="收入" name="0" />
        <el-tab-pane label="支出" name="1" />
      </el-tabs>
      <!-- <div class="table-head flex-row align-center">
        <span class="table-head-title">屏幕：</span>
        <el-select v-model="screenValue" placeholder="请选择">
          <el-option
            v-for="(item, index) in screenList"
            :key="index"
            :label="item.remark"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <div class="table-head-btn" id="default-btn">查询</div>
      </div> -->
      <el-table :data="historyList" v-if="pageParams.payStyleList[0] == 0">
        <el-table-column
          prop="historyId"
          align="center"
          label="业务编号"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          label="创建时间"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            <span v-if="row.createTime">{{ row.createTime | dateFormat }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="businessStyle"
          align="center"
          label="来源"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            <span>{{ businessStyleDesc(row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="buyTimeName"
          align="center"
          label="作用的屏幕"
          show-overflow-tooltip
          width="130rem"
        >
          <template v-slot="{ row }">
            <span>{{ screenDesc(row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="expireTime"
          align="center"
          label="有效期至"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            <span v-if="row.expireTime == '-1'">永久有效</span>
            <span v-else-if="row.expireTime != '0'">{{
              row.expireTime | dateFormat
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="time"
          align="center"
          label="时长(秒)"
          show-overflow-tooltip
          width="160rem"
        >
          <template v-slot="{ row }">
            <span>+{{ row.time }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="historyList" v-else>
        <el-table-column
          prop="historyId"
          align="center"
          label="业务编号"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          label="创建时间"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            <span v-if="row.createTime">{{ row.createTime | dateFormat }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="businessStyle"
          align="center"
          label="来源"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            <span>{{ businessStyleDesc(row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="buyTimeName"
          align="center"
          label="作用的屏幕"
          show-overflow-tooltip
          width="130rem"
        >
          <template v-slot="{ row }">
            <span>{{ screenDesc(row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderId"
          align="center"
          label="订单编号"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            <span v-if="row.orderId">{{ row.orderId }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="time"
          align="center"
          label="时长(秒)"
          show-overflow-tooltip
          width="160rem"
        >
          <template v-slot="{ row }">
            <span>-{{ row.time }}</span>
            <span v-if="row.refund">（已退回）</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="total > 20"
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="pageParams.pageLimit.page"
        :page-size="pageParams.pageLimit.limit"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getListBusinessHistory } from "@/api/request";
import menuTabs from "@/components/menuTabs.vue";

export default {
  components: {
    menuTabs,
  },
  data() {
    return {
      screenValue: "",
      historyList: [],
      pageParams: {
        payStyleList: ["0"],
        userId: "",
        pageLimit: {
          limit: 20,
          page: 1,
        },
      },
      total: 0,
    };
  },
  computed: {
    // userInfo() {
    //   return this.$store.state.userInfo;
    // },
    // 屏幕列表
    screenList() {
      return this.$store.state.screenList;
    },
  },
  created() {
    this.pageParams.userId = this.$route.query.userId;
    this.getListBusinessHistory();
  },
  methods: {
    getListBusinessHistory() {
      getListBusinessHistory(this.pageParams).then((res) => {
        // console.log(res);
        this.total = Number(res.pageResult.total);
        this.historyList = res.historyList;
      });
    },
    businessStyleDesc(row) {
      let desc = "";
      switch (row.businessStyle) {
        case "0":
          desc = `黄金会员专享（第${row.freeVipValidTimes}个月）`;
          break;
        case "1":
          desc = `1折优惠充值时长${row.buyTimeMoney}元`;
          break;
        case "2":
          if (row.refund) {
            desc = `退款-订单${row.orderId}`;
          } else if (row.packageOnline) {
            desc = `表白送祝福`;
          } else {
            desc = `线下充值${
              row.packageStyle == 0
                ? " (8800元套餐)"
                : row.packageStyle == 1
                  ? " (20000元套餐)"
                  : row.packageStyle == 2
                    ? " (30000元套餐)"
                    : row.packageStyle == 3
                      ? " (50000元套餐)"
                      : row.packageStyle == 4
                        ? ` (${row.packageMoney}元套餐)`
                        : ""
            }`;
          }
          break;
        case "3":
          desc = `邀请好友奖励`;
          break;
        case "4":
          desc = `累计消费满￥${row.costGiftMoney}赠送`;
          break;
        case "5":
          desc = `退款-订单${row.orderId}`;
          break;
        case "6":
          desc = `投放广告`;
          break;
        case "7":
          desc = `黄金会员专享（第${row.freeVipValidTimes}个月）-过期失效`;
          break;
        case "8":
          desc = `1折优惠充值时长${row.buyTimeMoney}元-过期失效`;
          break;
        case "9":
          if (row.refund) {
            desc = `退款-订单${row.orderId}-过期失效`;
          } else if (row.packageOnline) {
            desc = `表白送祝福-过期失效`;
          } else {
            desc = `线下充值${
              row.packageStyle == 0
                ? " (8800元套餐)"
                : row.packageStyle == 1
                  ? " (20000元套餐)"
                  : row.packageStyle == 2
                    ? " (30000元套餐)"
                    : row.packageStyle == 3
                      ? " (50000元套餐)"
                      : row.packageStyle == 4
                        ? ` (${row.packageMoney}元套餐)`
                        : ""
            }-过期失效`;
          }
          break;
        case "10":
          desc = `邀请好友奖励-过期失效`;
          break;
        case "11":
          desc = `累计消费满￥${row.costGiftMoney}赠送-过期失效`;
          break;
        case "12":
          desc = `取消会员`;
          break;
        case "13":
          desc = `普通充值时长${row.buyTimeMoney}元`;
          break;
        case "14":
          if (row.buyVipPackageType == 'm1') {
            desc = `基础版企业套餐${row.buyVipSecond}秒（按月付）`;
          } else if (row.buyVipPackageType == 'm2') {
            desc = `进取版企业套餐${row.buyVipSecond}秒（按月付）`;
          } else if (row.buyVipPackageType == 'm3') {
            desc = `尊贵版企业套餐${row.buyVipSecond}秒（按月付）`;
          } else if (row.buyVipPackageType == 'y1') {
            desc = `基础版企业套餐${row.buyVipSecond}秒（按年付）`;
          } else if (row.buyVipPackageType == 'y2') {
            desc = `进取版企业套餐${row.buyVipSecond}秒（按年付）`;
          } else if (row.buyVipPackageType == 'y3') {
            desc = `尊贵版企业套餐${row.buyVipSecond}秒（按年付）`;
          } else {
            desc = '-';
          }
          break;
        case "15":
          if (row.buyVipPackageType == 'm1') {
            desc = `基础版企业套餐${row.buyVipSecond}秒（按月付）-过期失效`;
          } else if (row.buyVipPackageType == 'm2') {
            desc = `进取版企业套餐${row.buyVipSecond}秒（按月付）-过期失效`;
          } else if (row.buyVipPackageType == 'm3') {
            desc = `尊贵版企业套餐${row.buyVipSecond}秒（按月付）-过期失效`;
          } else if (row.buyVipPackageType == 'y1') {
            desc = `基础版企业套餐${row.buyVipSecond}秒（按年付）-过期失效`;
          } else if (row.buyVipPackageType == 'y2') {
            desc = `进取版企业套餐${row.buyVipSecond}秒（按年付）-过期失效`;
          } else if (row.buyVipPackageType == 'y3') {
            desc = `尊贵版企业套餐${row.buyVipSecond}秒（按年付）-过期失效`;
          } else {
            desc = '-';
          }
          break;
        case "16":
          desc = `参与抽奖奖励`;
          break;
        case "17":
          desc = `参与抽奖奖励-过期失效`;
          break;
        case "18":
          desc = `代金券结算时长`;
          break;
        case "19":
          desc = `代金券结算时长-过期失效`;
          break;
        default:
          desc = "-";
          break;
      }
      return desc;
    },
    screenDesc(row) {
      let desc = "";
      switch (row.businessStyle) {
        case "0":
          desc = row.freeVipName
            ? this.screenList.find((v) => v.name == row.freeVipName).remark
            : "全部屏幕";
          break;
        case "1":
          desc = this.screenList.find((v) => v.name == row.buyTimeName).remark;
          break;
        case "2":
          desc = `全部屏幕`;
          break;
        case "3":
          desc = `全部屏幕`;
          break;
        case "4":
          desc = `全部屏幕`;
          break;
        case "5":
          desc = this.screenList.find(
            (v) => v.name == row.orderAttachName
          ).remark;
          break;
        case "6":
          desc = this.screenList.find(
            (v) => v.name == row.orderAttachName
          ).remark;
          break;
        case "7":
          desc = row.freeVipName
            ? this.screenList.find((v) => v.name == row.freeVipName).remark
            : "全部屏幕";
          break;
        case "8":
          desc = this.screenList.find((v) => v.name == row.buyTimeName).remark;
          break;
        case "9":
          desc = `全部屏幕`;
          break;
        case "10":
          desc = `全部屏幕`;
          break;
        case "11":
          desc = `全部屏幕`;
          break;
        case "12":
          desc = row.freeVipName
            ? this.screenList.find((v) => v.name == row.freeVipName).remark
            : "全部屏幕";
          break;
        case "13":
          desc = this.screenList.find((v) => v.name == row.buyTimeName).remark;
          break;
        case "14":
          desc = `全部屏幕`;
          break;
        case "15":
          desc = `全部屏幕`;
          break;
        case "16":
          desc = `全部屏幕`;
          break;
        case "17":
          desc = `全部屏幕`;
          break;
        case "18":
          desc = `全部屏幕`;
          break;
        case "19":
          desc = `全部屏幕`;
          break;
        default:
          desc = "-";
          break;
      }
      return desc;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageParams.pageLimit.page = Number(val);
      this.getListBusinessHistory();
    },
    handleClick() {
      this.pageParams.pageLimit.page = 1;
      this.getListBusinessHistory();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__header {
  margin: 0;
  height: 100%;
}
::v-deep .el-tabs__active-bar {
  background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
  width: 32rem !important;
  height: 2rem;
}
::v-deep .el-tabs__nav {
  height: 62rem;
}
::v-deep .el-tabs__item {
  height: 60rem;
  line-height: 60rem;
  position: relative;
  top: 2rem;
  font-size: 16rem;
  padding: 0 20rem;
}
::v-deep th {
  height: 52rem;
  font-size: 16rem;
  color: #9d9f9d;
  font-weight: 400;
}
::v-deep .el-table th.el-table__cell {
  background: #f8f8f8;
}
::v-deep .el-table th.el-table__cell.is-leaf {
  border-bottom: 2px solid #fff;
}
::v-deep .el-table td.el-table__cell {
  border-bottom: 2px solid #fff;
}
::v-deep td {
  height: 52rem;
  font-size: 16rem;
  color: #3b3e3c;
  font-weight: 400;
}
::v-deep tr {
  background: #f8f8f8;
}
::v-deep .el-table .cell {
  height: 28rem;
  line-height: 28rem;
}
::v-deep .el-table::before {
  display: none;
}
::v-deep .el-pagination .btn-prev .el-icon,
::v-deep .el-pagination .btn-next .el-icon {
  font-size: 14rem;
}
::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .btn-next,
::v-deep .el-pagination.is-background .el-pager li {
  width: 28rem;
  height: 28rem;
  min-width: 28rem;
  line-height: 28rem;
  background: #ffffff;
  border-radius: 50%;
  font-size: 14rem;
  font-weight: 500;
  color: #8a8c8b;
  margin: 0 5rem;
}
::v-deep .el-select .el-input .el-select__caret {
  font-size: 20rem;
  color: #252927;
  line-height: 44rem;
}
::v-deep .el-input__inner {
  height: 44rem;
  line-height: 44rem;
  border: none;
  border-radius: 11rem;
  font-size: 16rem;
  font-weight: 400;
  color: #252927;
}
.page {
  width: 100%;
  background-color: #fff;
  min-height: calc(100vh - 233rem);
  padding: 60rem 320rem 0;
  .page-right {
    width: 1060rem;
    .el-tabs {
      width: 100%;
      height: 62rem;
      background: #f8f8f8;
      border-radius: 6rem 6rem 0px 0px;
      // margin-top: 30rem;
      padding: 0 0 0 36rem;
      margin-bottom: 2px;
    }
    .el-table {
      margin: 16rem 0 20rem 0;
    }
    .table-head {
      width: 100%;
      height: 68rem;
      background: #f8f8f8;
      border-radius: 0px 0px 6rem 6rem;
      padding-left: 36rem;
      .table-head-title {
        font-size: 16rem;
        color: #252927;
        line-height: 22rem;
      }
      .el-select {
        width: 292rem;
        height: 44rem;
        margin-left: 8rem;
        background: #ffffff;
        border-radius: 11rem;
      }
      .table-head-btn {
        width: 83rem;
        height: 42rem;
        background: #ffffff;
        border-radius: 11rem;
        border: 1px solid #dedede;
        text-align: center;
        line-height: 42rem;
        font-size: 16rem;
        font-weight: 500;
        color: #252927;
        margin-left: 19rem;
        cursor: pointer;
      }
    }
    .el-pagination {
      margin: 28rem 0 100rem 0;
    }
  }
}
</style>
